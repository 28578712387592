/* page.css */
.header {
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem; /* 64px, similar to h-16 in Tailwind */
  padding: 0 1rem; /* Adds some spacing around the content */
  border-bottom: 1px solid #e5e7eb; /* Light gray border for separation */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for better visibility */
}
  
.header-title {
  font-size: 1.25rem; /* 20px, similar to text-xl in Tailwind */
}

.aui-thread-viewport-footer {
  z-index: 2; /* Setting this z-index higher ensures the table does not flow over the search bar */
}

.aui-composer-input {
  font-size: 16px;
}

.aui-thread-welcome-suggestion-text {
  /* Max number of lines in suggestions */ 
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

/* Target iPhone devices (portrait and landscape) */
@media screen and (max-width: 768px) {
  .aui-thread-welcome-suggestion-container {
    flex-direction: column; 
    align-items: center;
  }
}
