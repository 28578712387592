.data-grid {
    overflow-x: auto; /* Enables horizontal scrolling */
    max-width: 100%; /* Constrain width */
    box-sizing: border-box; /* Include padding in width calculation */
    padding-top: 16px; /* Padding for visual appeal */
  }
  
/* Style for left fixed columns */
.fixed-column-left {
    background-color: #ffffff; /* Light gray background */
    font-weight: bold; /* Bold text */
    border-right: 1px solid #d9d9d9; /* Add a border to separate from other columns */
}

  /* White background for even rows */
.even-row {
    background-color: #ffffff; /* Pure white */
}

/* Light gray background for odd rows */
.odd-row {
    background-color: #f5f5f5; /* Light gray */
}

/* Hover effect for all rows (darker gray) */
.ant-table-row:hover td {
    background-color: #e0e0e0 !important; /* Medium gray for hover */
}

/* Apply global styling for all tables */
.ant-table {
  .ant-table-thead > tr > th {
    padding: 4px 8px; /* Reduce header cell padding */
  }
  .ant-table-tbody > tr > td {
    padding: 4px 8px; /* Reduce row cell padding */
  }
}

table {
    font-size: 12px;
}

.betting-info-toggle {
  margin-bottom: 4px;
}

/* Target iPhone devices (portrait and landscape) */
@media screen and (max-width: 768px) {
    table {
        font-size: 8px;
      }
  }

